
function NextButtonContainer({handleEasy, handleJustRight, handleHard, justRightButtonRef}) {
  return (
    <div style={{ 
      display: 'flex', 
      flexDirection: 'row',
      justifyContent: 'space-around',
      width: '80%' 
    }}>
      <button onClick={handleEasy} style={{minWidth:'30%'}}>TOO EASY<br/><small>[1]</small></button>
      <button  onClick={handleJustRight} style={{minWidth:'30%'}} ref={justRightButtonRef}>JUST RIGHT<br/><small>[2]</small></button>
      <button onClick={handleHard} style={{minWidth:'30%'}}>TOO HARD<br/><small>[3]</small></button>
    </div>
  );
}

export default NextButtonContainer;