import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import headerStyles from '../styles/component-header.module.css';
import {ReactComponent as Icon} from '../icons/icon_streak.svg';
import {ReactComponent as IconHamburger} from '../icons/icon_hamburger.svg';
import { useEffect, useState } from 'react';
import { isLastReviewBeforeToday } from '../utils';


function StreakBox() {
  const [iconClass, setIconClass] = useState('');
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if((user.streak) === 0 || isLastReviewBeforeToday(user.lastReviewDate)) {
      setIconClass(`${headerStyles.iconStreak} ${headerStyles.iconStreakInactive}`)
    } else {      
      setIconClass(`${headerStyles.iconStreak} ${headerStyles.iconStreakActive}`)
    }
  }, [user])

  return (
    <div className={headerStyles.headerBox}>
      <Icon className={iconClass}/>
      <div>
        <h3>{user.streak}</h3>
      </div>
    </div>
  );
}

function HamburgerBox({toggleDropdown}) {
  return (
    <div className={`${headerStyles.headerBox} ${headerStyles.hamburgerBox}`} onClick={toggleDropdown}>
      <IconHamburger className={headerStyles.iconHamburger}/>
    </div>
  )
}

function HeaderBar() {
  const isLoggedIn = useSelector((state) => state.user).isLoggedIn;
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const closeDropdown = () => {
    setDropdownVisible(false);
  };

  return (
    <header className={headerStyles.header}>
      <div className={headerStyles.left}>
        {isLoggedIn ? <NavLink to="/home"><span className={headerStyles.pageLogo}>lingoleaf</span></NavLink> : <NavLink to="/"><span className={headerStyles.pageLogo}>lingoleaf</span></NavLink>}
      </div>
      <div className={headerStyles.middle}>
        {isLoggedIn && <StreakBox/>}
      </div>
      <div className={headerStyles.right}>
        {isLoggedIn && <HamburgerBox toggleDropdown={toggleDropdown} />}
        {isLoggedIn && dropdownVisible && (
          <>
            <div className={headerStyles.dropdownBackdrop} onClick={closeDropdown}></div>
            <div className={headerStyles.dropdownMenu}>
              <NavLink to="/settings" className={headerStyles.navLink} onClick={() => setDropdownVisible(false)}>
                Settings
              </NavLink>
              <NavLink to="/logout" className={headerStyles.navLink} onClick={() => setDropdownVisible(false)}>
                Sign Out
              </NavLink>
            </div>
          </>
        )}
      </div>
    </header>
  );
}

export default HeaderBar;
