import pageStyles from '../styles/page-common.module.css';

function DefaultPayment() {
  return (
    <div className={pageStyles.styledPage}>
      <h1>Default Payment Page</h1>
    </div>
  );
}

export default DefaultPayment;