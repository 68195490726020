import { useEffect, useRef } from 'react';
import { useNavigate, useLocation, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LanguageDropdown from '../components/LanguageDropdown';
import pageStyles from '../styles/page-common.module.css';
import { LONG_NAMES } from '../constants';


function Home() {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const startButtonRef = useRef(null);

  function handleStart() {
    navigate('/review');
  }

  useEffect(() => {
    if (startButtonRef.current) {
      startButtonRef.current.focus();
    }     
  }, [location]);

  return (
    <div className={pageStyles.styledPage}>
      <h1>Welcome, {user.username}!</h1>
      {user.subscriptionStatus === 'past_due' && (
        <div className={pageStyles.alertBanner}>
          <p>Your subscription renewal payments have failed. Your account will remain active for 30 days since the first failed payment. Please update your payment method <NavLink to='/update-payment-method'>here</NavLink>.</p>
        </div>
      )}
      {user.subscriptionStatus === 'paused' && (
        <div className={pageStyles.alertBanner}>
          <p>Your subscription is paused.</p>
        </div>
      )}
      {user.subscriptionStatus === 'trialing' && (
        <div className={pageStyles.alertBanner}>
          <p>You are on a free trial. Your trial ends on <strong>{user.subscriptionNextBilledAt.slice(0, 10)}</strong>.</p>
        </div>
      )}
      <p>Choose your learning language:</p>
      <LanguageDropdown/>
      <p>Current {LONG_NAMES[user.currentLanguage]} level: {user.levels[user.currentLanguage]}</p>
      <button ref={startButtonRef} onClick={handleStart} disabled={user.subscriptionStatus === 'paused'}>START</button>
    </div>
  );
}

export default Home;