import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { initializePaddle } from '@paddle/paddle-js';
import pageStyles from '../styles/page-common.module.css';
import { PADDLE_LIVE, PADDLE_PRICE_ID, PADDLE_CLIENT_TOKEN } from '../constants';


function Pricing() {

  const [paddle, setPaddle] = useState();
  const [ip, setIp] = useState('');
  const [priceString, setPriceString] = useState('[estimating price]');


  // get customer IP address on mount
  useEffect(() => {
    const fetchIp = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        setIp(response.data.ip);
      } catch (error) {
        console.error('Error fetching IP:', error);
      }
    };

    fetchIp();
  }, []);

  // download and initialize Paddle instance from CDN
  useEffect(() => {
  
    // config settings for Paddle
    const paddleConfig = {
      token: PADDLE_CLIENT_TOKEN,
    };

    // add environment key only if not in production mode
    if (!PADDLE_LIVE) {
      paddleConfig.environment = 'sandbox';
    }

    // initialise Paddle
    initializePaddle(paddleConfig).then(
      paddleInstance => {
        if (paddleInstance) {
          setPaddle(paddleInstance);
        }
      },
    );
  }, []);

  useEffect(() => {
    if (ip && paddle) {
      const priceConfig = {
        items: [{ priceId: PADDLE_PRICE_ID, quantity: 1 }],
        customerIpAddress: ip,
      }
      paddle.PricePreview(priceConfig)
      .then((result) => {
        setPriceString(`${result.data.details.lineItems[0].formattedTotals.total} (${result.data.currencyCode})`)
      })
      .catch((error) => {
        console.error(error);
      });
    }
  }, [paddle, ip])



  return (
    <div className={pageStyles.styledPage}>
      <h1>Pricing</h1>
      <p>LingoLeaf has a <strong>2-week free trial</strong>, followed by a monthly subscription.</p>
      <p>The base price is $2.50 (USD) per month, but we have various country-specific special prices in place. Based on where you are currently accessing the site from, we expect your price to be:</p>
      <p><strong>{priceString} / month</strong></p>
      <p><small>This price might update when you enter your payment details at checkout, if your billing country is different from your current location.</small></p>
      <NavLink to="/register" style={{display: 'contents'}}><button>Start free trial</button></NavLink>
    </div>
  );
}

export default Pricing;