import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoggedIn: false,
  currentLanguage: "",
  email: "",
  hash: "",
  paddleCustomerID: "",
  paddleSubscriptionID: "",
  subscriptionStatus: "",
  subscriptionNextBilledAt: "",
  subscriptionScheduledChange: "",
  lastReviewDate: null,
  levels: null,
  streak: 0,
  username: "",
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginUser: (state, action) => {
      const {
        currentLanguage, email, hash, paddleCustomerID,
        paddleSubscriptionID, subscriptionStatus,
        subscriptionNextBilledAt, subscriptionScheduledChange,
        lastReviewDate, levels, streak, username
      } = action.payload;
      return {
        ...state,
        isLoggedIn: true,
        currentLanguage,
        email,
        hash,
        paddleCustomerID,
        paddleSubscriptionID,
        subscriptionStatus,
        subscriptionNextBilledAt,
        subscriptionScheduledChange,
        lastReviewDate,
        levels,
        streak,
        username,
      };
    },
    logoutUser: () => initialState,
    setLanguage: (state, action) => { 
      state.currentLanguage = action.payload;
    },
    setLevel: (state, action) => {
      const {language, level} = action.payload;
      state.levels = {...state.levels, [language]: level}
    },
    setLastReviewDate: (state, action) => {
      state.lastReviewDate = action.payload;
    },
    setStreak: (state, action) => {
      state.streak = action.payload;
    } 
  },
});

export const { loginUser, logoutUser, setLanguage, setLevel, setLastReviewDate, setStreak } = userSlice.actions;
export default userSlice.reducer;
