import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { initializePaddle, getPaddleInstance } from '@paddle/paddle-js';
import pageStyles from '../styles/page-common.module.css';
import { PADDLE_LIVE, PADDLE_CLIENT_TOKEN } from '../constants';
import { getPaddleUpdateTransactionID } from '../backend';


function UpdatePaymentMethod() {

  const [paddle, setPaddle] = useState();
  const [checkoutComplete, setCheckoutComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const username = useSelector((state) => state.user).username;

  // download and initialize Paddle instance from CDN
  useEffect(() => {
    
    // config settings for Paddle
    const paddleConfig = {
      token: PADDLE_CLIENT_TOKEN,
      eventCallback: async function(data) {
        if (data.name === "checkout.completed") {
          setLoading(false);
          setCheckoutComplete(true);
        } else if (data.name === "checkout.closed") {
          setLoading(false);
        }
      }
    };

    // add environment key only if not in production mode
    if (!PADDLE_LIVE) {
      paddleConfig.environment = 'sandbox';
    }

    // initialise Paddle
    initializePaddle(paddleConfig).then(
      paddleInstance => {
        if (paddleInstance) {
          setPaddle(paddleInstance);
        }
      },
    );
  }, []);

  // when checkout complete, close checkout window after 3-second delay
  useEffect(() => {
    if (checkoutComplete) {
      const timeout = setTimeout(() => {
        const p = getPaddleInstance();
        p.Checkout.close();
      }, 3000);
  
      // cleanup timeout if component unmounts
      return () => clearTimeout(timeout);
    }
  }, [checkoutComplete]);

  // when button clicked, query transaction ID then open checkout
  const handleButtonClick = async () => {
    setLoading(true);
    const txnID = await getPaddleUpdateTransactionID(username);
    if (paddle) {
      paddle.Checkout.open({ transactionId: txnID });
    }
  };

  return (
    <div className={pageStyles.styledPage}>
      <h1>Update Payment Method</h1>
      <p>Press the button below to open a dialog where you can update your payment details with our third-party payment processor.</p>
      <button onClick={handleButtonClick} disabled={loading}>{loading ? 'Loading...' : 'Update payment method'}</button>
      <NavLink to="/settings" style={{display: 'contents'}}><button>Return to settings</button></NavLink>
    </div>
  );
}

export default UpdatePaymentMethod;