import pageStyles from '../styles/page-common.module.css';


function NotFound() {

  return (
    <div className={pageStyles.styledPage}>
      <h1>Page not found, sorry!</h1>
    </div>
  );
}

export default NotFound;