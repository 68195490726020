import pageStyles from '../styles/page-common.module.css';
import { NavLink } from 'react-router-dom';

function About() {
  return (
    <div className={pageStyles.styledPageLeftAligned}>
      <h1>About LingoLeaf</h1>
      <p>LingoLeaf is a web app you can use to help you learn a new language. For more information about how it works and how to use it, see our <NavLink to='/faqs'>FAQs</NavLink>.</p>
      <p>LingoLeaf is the work of a solo developer based in Edinburgh, Scotland, UK. He learns several languages in his spare time, including Japanese. The idea for LingoLeaf came after he noticed that it was very difficult to find fresh reading material at the right level for him to practice all the <a rel="noreferrer" target="_blank" href="https://en.wikipedia.org/wiki/Kanji">漢字</a> he was memorising!</p>
    </div>
  );
}

export default About;