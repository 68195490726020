
const LONG_NAMES = {
    'de': 'German',
    'fr': 'French',
    'es': 'Spanish'
}

// set PADDLE_LIVE to true to switch sandbox -> live
const PADDLE_LIVE = false
const PADDLE_SANDBOX_CLIENT_TOKEN = "test_771ed15bf5b411058ef171dd05c"
const PADDLE_LIVE_CLIENT_TOKEN = "live_7611d833c8cf1b6b135a5370555"
const PADDLE_SANDBOX_PRICE_ID = "pri_01jer1xmaq0jz0y6gee8jf1v1m"
const PADDLE_LIVE_PRICE_ID = "pri_01jer27ykgvnnym2m108v7hngs"
const PADDLE_PRICE_ID = PADDLE_LIVE ? PADDLE_LIVE_PRICE_ID : PADDLE_SANDBOX_PRICE_ID
const PADDLE_CLIENT_TOKEN = PADDLE_LIVE ? PADDLE_LIVE_CLIENT_TOKEN : PADDLE_SANDBOX_CLIENT_TOKEN

export { LONG_NAMES, PADDLE_LIVE, PADDLE_PRICE_ID, PADDLE_CLIENT_TOKEN }