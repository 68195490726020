import { useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import pageStyles from '../styles/page-common.module.css';
import { deleteUser } from '../backend';

function AccountDeletion() {

  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const handleDeleteAccount = async () => {
    try {
      const response = await deleteUser(user.username);
      if(response.status === 200) {
        navigate('/account-deletion-success');
      }
    } catch (error) {
      // Handle errors
      console.error('Error deleting account:', error);
    }
  };

  return (
    <div className={pageStyles.styledPage}>
      <h1>Account Deletion</h1>
      <h2>Are you sure you want to delete your account?</h2>
      <p>Note: this will also cancel your payment subscription.</p>
      <button onClick={handleDeleteAccount}>Yes, delete my account</button>
      <Link to="/settings" style={{display: 'contents'}}><button>No, return to settings</button></Link>
    </div>
  );
}

export default AccountDeletion;