import { useState, useEffect, Fragment, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MainTextbox from '../components/MainTextbox';
import NextButtonContainer from '../components/NextButtonContainer';
import pageStyles from '../styles/page-common.module.css';
import { getPassages } from '../backend';
import { LONG_NAMES } from '../constants';


function getRandomElements(arr, numElements) {
  let shuffled = [...arr]; // Clone the array to avoid modifying the original array

  // Fisher-Yates Shuffle
  for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]]; // Swap elements
  }

  return shuffled.slice(0, numElements); // Get the first 'numElements' elements
}

function Review() {

  // constant: number of passages per review
  const REVIEWLENGTH = 5;

  // get some react hook functions
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // get redux states: JWT token, chosen language and user level
  //const token = useSelector((state) => state.user).token;
  const user = useSelector((state) => state.user);
  const language = user.currentLanguage;
  const level = user.levels[language]

  // react states
  const [passageArray, setPassageArray] = useState([]);
  const [passageArrayIndex, setPassageArrayIndex] = useState(-1);
  const [passage, setPassage] = useState(null);
  const [translated, setTranslated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState({
    'level': level,
    'easy': 0,
    'justRight': 0,
    'hard': 0,
  });

  // refs for focus management
  const translateButtonRef = useRef(null);
  const justRightButtonRef = useRef(null);

  // on startup, download 5 passages from database
  useEffect(() => {
    async function loadPassages() {
      setLoading(true);
      const response = await getPassages(language, level);
      setPassageArray(getRandomElements(Object.values(response), REVIEWLENGTH));
    }
    loadPassages();
  }, [language, level, dispatch]);

  // when passage array finished loading, set index to 0
  useEffect(() => {
    if(passageArray.length !== 0) {
      setPassageArrayIndex(0)
    }
  }, [passageArray]);

  // when index updated, set passage or navigate away
  useEffect(() => {
    if (passageArrayIndex !== -1) {
      if(passageArrayIndex === REVIEWLENGTH) {
        navigate('/review-complete', { state: results });
      } else {
        setPassage(passageArray[passageArrayIndex]);
      }
    }
  }, [passageArrayIndex, passageArray, results, navigate]);

  useEffect(() => {
    if (!loading && !translated && translateButtonRef.current) {
      translateButtonRef.current.focus();
    } else if (!loading && translated && justRightButtonRef.current) {
      justRightButtonRef.current.focus();
    }
  }, [loading, translated]);

  // when passage changed, set loading and translated to false
  useEffect(() => {
    setTranslated(false);
    if (passage !== null) {
      setLoading(false);
      if (translateButtonRef.current) {
        translateButtonRef.current.focus();
      }
    } else {
      setLoading(true);
    }
  }, [passage]);

  // handler for translate button
  function handleTranslate() {
    setTranslated(true);
    if (justRightButtonRef.current) {
      justRightButtonRef.current.focus();
    }
  }

  const handleEasy = useCallback(() => {
    const newVal = results.easy + 1;
    setResults((prevState) => ({
      ...prevState,
      easy: newVal,
    }));
    setPassageArrayIndex((prevIndex) => prevIndex + 1);
  }, [results.easy]);

  const handleJustRight = useCallback(() => {
    const newVal = results.justRight + 1;
    setResults((prevState) => ({
      ...prevState,
      justRight: newVal,
    }));
    setPassageArrayIndex((prevIndex) => prevIndex + 1);
  }, [results.justRight]);

  const handleHard = useCallback(() => {
    const newVal = results.hard + 1;
    setResults((prevState) => ({
      ...prevState,
      hard: newVal,
    }));
    setPassageArrayIndex((prevIndex) => prevIndex + 1);
  }, [results.hard]);

  useEffect(() => {
    function handleKeyPress(event) {
      if (!loading && translated) {
        switch (event.key) {
          case '1':
            handleEasy();
            break;
          case '2':
            handleJustRight();
            break;
          case '3':
            handleHard();
            break;
          default:
            break;
        }
      }
    }

    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [loading, translated, handleEasy, handleHard, handleJustRight]);

  return (
    <div className={pageStyles.styledPage}>
      <h1>{LONG_NAMES[language]}: Level {level}</h1>
      {loading && <MainTextbox textElement={<p>Loading...</p>}/>}
      {!loading && <h3>Passage {passageArrayIndex + 1} of 5</h3>}
      {!loading && <MainTextbox textElement={passage.passage.split('\n').map((line, index, array) => (<Fragment key={index}><p>{line}</p>{index<array.length-1 &&<br/>}</Fragment>))}/>}
      {!loading && !translated && <button ref={translateButtonRef} onClick={handleTranslate}>TRANSLATE</button>}
      {translated && <MainTextbox textElement={passage.translation.split('\n').map((line, index, array) => (<Fragment key={index}><p>{line}</p>{index<array.length-1 &&<br/>}</Fragment>))}/>}
      {translated && <NextButtonContainer handleEasy={handleEasy} handleJustRight={handleJustRight} handleHard={handleHard} justRightButtonRef={justRightButtonRef}/>}
      {!loading && <p>Passage ID: {passage.index}</p>}
    </div>
  );
}

export default Review;
    