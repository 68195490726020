import pageStyles from '../styles/page-common.module.css';

function Contact() {
  return (
    <div className={pageStyles.styledPage}>
      <h1>Contact</h1>
      <p>Send an email to <strong>contact "at" lingoleaf.io</strong> with any queries or feedback.</p>
      <p>We try to reply to everyone within a couple of business days, max.</p>
    </div>
  );
}

export default Contact;