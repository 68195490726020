import { useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import pageStyles from '../styles/page-common.module.css';


function RegistrationSuccess() {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.user).isLoggedIn;

  // shouldn't be here if logged in; navigate to home
  useEffect(() => {
    if (isLoggedIn) {
      navigate('/home');
    }
  }, [navigate, isLoggedIn]);

  return (
    <div className={pageStyles.styledPage}>
      <h1>Welcome to LingoLeaf!</h1>
      <h2>Your 2-week free trial is now active.</h2>
      <h2>To complete registration, please verify your email address.</h2>
      <p>We have sent an email to the address you provided. <strong>Remember to check your junk folder!</strong></p> 
      <p>Please click the link in the email to complete the registration process.</p>
      <p>Didn't get the email? <NavLink to='/resend-confirmation'>Resend</NavLink></p>
    </div>
  );
}

export default RegistrationSuccess;