import { NavLink } from 'react-router-dom';
import pageStyles from '../styles/page-common.module.css';

function Terms() {
  return (
    <div className={pageStyles.styledPageLeftAligned}>
      
      <h1>Terms & Conditions</h1>
      
      <p><strong>Effective Date: December 1, 2024</strong> </p>
      <p><strong>Last Updated: November 19, 2024</strong> </p>

      <h2>A. Overview and Definitions</h2>
      <p>Welcome to LingoLeaf! LingoLeaf is a language-learning application designed to enhance reading comprehension through exposure to text passages calibrated to the level of the user. Users will read texts in their target language and rate their difficulty, which allows the App to adjust the difficulty level of future passages. Several learning languages are available, and more are likely to be added in future.</p>
      <p>This website lingoleaf.io and its contents and software (collectively the "Website" or "App" or "Service") are operated by Aneesh P. Naik, a sole trader trading as LingoLeaf ("we", "us", "our"). LingoLeaf is based in Edinburgh, Scotland, UK. These Terms and Conditions ("Terms" or "Agreement") govern your access to and use of the Service. By accessing or using the Service, you agree to comply with and be bound by these Terms. If you do not agree with these Terms, please refrain from using our Service.</p>

      <h2>B. Registration</h2>
      In order to register for an account on the Website:
      <ul>
        <li>You agree to provide accurate, current and complete information about yourself as requested by us.</li>
        <li>You must be a human. Accounts created using automated methods are not permitted.</li>
        <li>You must be age 13 or older.</li>
        <li>You must be a single person, i.e., an account may not be shared by multiple people.</li>
      </ul>

      <h2>C. Service Usage</h2>
      <ul>
        <li>You are fully responsible for all use of your account and for any actions that take place through your account.</li>
        <li>You will maintain and promptly update any registration information you provide to us, to keep such information accurate, current and complete</li>
        <li>You are responsible for maintaining the security of your login credentials. LingoLeaf cannot and will not be liable for any loss or damage from your failure to comply with this security obligation.</li>
        <li>You will promptly notify us if you become aware of any unauthorised use of, or access to, our Service through your account, including any unauthorised use of your login credentials.</li>
        <li>You agree not do anything which would assist anyone who is not a registered user to gain access to a part of the Service reserved for registered users.</li>
        <li>You agree not to take any actions which impair the functionality of the Service.</li>
        <li>Your use of the Website and Service must not violate any applicable laws, including copyright or trademark laws, export control laws, or other laws in your jurisdiction. You are responsible for making sure that your use of the Service is in compliance with laws and any applicable regulations.</li>
        <li>You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service without our express written permission.</li>
      </ul>

      <h2>D. Subscription Payments</h2>
      <ul>
        <li>The Service is provided on the basis of an automatically renewing periodic subscription. The price per subscription period will be clearly communicated to you at the time of registration. You will be billed in advance at the start of each subscription period. After your initial subscription period, and again after any subsequent subscription period, your subscription will automatically renew for an additional equivalent period. This continues until you terminate it as set forth below.</li>
        <li>You might be offered a 'free trial' before your first subscription period, allowing free access to the full functionality of the Service. After the free trial, continued access requires a paid subscription.</li>
        <li>Except at the discretion of LingoLeaf, a free trial will only be available for first-time users. A user who has previously cancelled an account and has started a new account will not eligible for a free trial.</li>
        <li>You are responsible for all fees, including taxes, associated with your use of the Service. You agree to pay LingoLeaf any charge incurred in connection with your use of the Service. You are responsible for providing us with a valid means of payment for paid accounts.</li>
        <li>We may change the subscription price at any time. We will notify you via email of any changes in price, at least 7 days in advance of the effective date of the price change. If this effective date falls within your ongoing subscription period, your subscription period will continue at your originally agreed price, and the updated price will apply only to subequent subscription periods.</li>
        <li>If you cancel your subscription, you will continue to have access to the service through the end of your current subscription period.</li>
        <li>All payments are nonrefundable. However, LingoLeaf may grant refunds or credits on a case-by-case basis at our sole discretion. Such a grant of a refund or credit in one instance does not obligate us to provide a refund or credit in the future, under any circumstances.</li>
        <li>All financial transactions made in connection with the Service will be processed by a third party processor, Paddle, in accordance with their respective <a href='https://www.paddle.com/legal/checkout-buyer-terms'>terms of use</a>, and <a href='https://www.paddle.com/legal/privacy'>privacy policy</a>. In no event will LingoLeaf be responsible for the actions or inactions of the third party payment processor, including, but not limited to, system downtime or payment service outages.</li>
      </ul>

      <h2>E. Cancellation</h2>
      <p>You can cancel your subscription at any time via the Website "Settings" interface. Your access to the Service will remain in effect until the end of your current subscription period. At the end of the subscription period, access to the Service will be revoked and your user account will be deleted. To resubscribe, you will need to register for a new account, either using the same or new login credentials. Upon account deletion, we will fully and immediately remove your data from our records, retaining only the information necessary to comply with our legal obligations.</p>

      <h2>F. Intellectual Property</h2>
      <p>All content, features, and functionality on LingoLeaf, including but not limited to text, graphics, logos, and software, are the property of LingoLeaf or its licensors and are protected by copyright, trademark, and other intellectual property laws. You may not copy, modify, distribute, or use any part of the Website without prior written permission.</p>

      <h2>G. Copyright</h2>
      <p>If you believe that content on our Service violates your copyright, please contact us</p>

      <h2>H. Disclaimer of Warranty</h2>
      <p>LingoLeaf provides the Service “as is” and “as available,” without warranty of any kind. Without limiting this, we expressly disclaim all warranties, whether express, implied or statutory, regarding the Service including without limitation any warranty of merchantability, fitness for a particular purpose, title, security, accuracy and non-infringement.</p>
      <p>LingoLeaf does not warrant that the Service will meet your requirements; that the Service will be uninterrupted, timely, secure, or error-free; that the information provided through the Service is accurate, reliable or correct; that any defects or errors will be corrected; that the Service will be available at any particular time or location; or that the Service is free of viruses or other harmful components. You assume full responsibility and risk of loss resulting from your downloading and/or use of files, information, content or other material obtained from the Service.</p>

      <h2>I. Limitation of Liability</h2>
      <p>You understand and agree that we will not be liable to you or any third party for any loss of profits, use, goodwill, or data, or for any incidental, indirect, special, consequential or exemplary damages, however arising, that result from:</p>
      <ul>
        <li>your use or inability to use the Service;</li>
        <li>any modification, price change, suspension or discontinuance of the Service;</li>
        <li>the Service generally or the software or systems that make the Service available;</li>
        <li>unauthorized access to or alterations of your transmissions or data;</li>
        <li>statements or conduct of any third party on the Service;</li>
        <li>the use of websites or services, as well as the content, goods, and services on those websites and services, mentioned by the Service or linked from the Website;</li>
        <li>any other user interactions that you input or receive through your use of the Service; or</li>
        <li>any other matter relating to the Service.</li>
      </ul>
      <p>Our liability is limited whether or not we have been informed of the possibility of such damages, and even if a remedy set forth in this Agreement is found to have failed of its essential purpose. We will have no liability for any failure or delay due to matters beyond our reasonable control.</p>

      <h2>J. Release and Indemnification</h2>
      <p>If you have a dispute with one or more users, you agree to release LingoLeaf from any and all claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with such disputes.</p>
      <p>You agree to indemnify us, defend us, and hold us harmless from and against any and all claims, liabilities, and expenses, including attorneys’ fees, arising out of your use of the Service, including but not limited to your violation of this Agreement, provided that LingoLeaf (1) promptly gives you written notice of the claim, demand, suit or proceeding; (2) gives you sole control of the defense and settlement of the claim, demand, suit or proceeding (provided that you may not settle any claim, demand, suit or proceeding unless the settlement unconditionally releases LingoLeaf of all liability); and (3) provides to you all reasonable assistance, at your expense.</p>

      <h2>K. Privacy</h2>
      <p>Use of the Service is also governed by our <NavLink to='/privacy'>Privacy Policy</NavLink>. By using the Service, you consent to the terms of the Privacy Policy.</p>

      <h2>L. Miscellaneous</h2>
      <ul>
        <li>We reserve the right at any time and from time to time to modify or discontinue, temporarily or permanently, the Website (or any part of it) with or without notice.</li>
        <li>We reserve the right to refuse service to anyone for any reason at any time.</li>
        <li>These Terms are governed by Scots law and the parties agree to submit to the exclusive jurisdiction of the courts of Scotland.</li>
        <li>LingoLeaf may assign or delegate these Terms, in whole or in part, to any person or entity at any time with or without your consent. You may not assign or delegate any rights or obligations under these Terms without our prior written consent, and any unauthorized assignment and delegation by you is void.</li>
        <li>If any part of this Agreement is held invalid or unenforceable, that portion of the Agreement will be construed to reflect the parties’ original intent. The remaining portions will remain in full force and effect.</li>
        <li>Any failure on the part of LingoLeaf to enforce any provision of this Agreement will not be considered a waiver of our right to enforce such provision.</li>
        <li>LingoLeaf reserves the right to suspend or terminate your account and access to the Service at any time, with or without notice, for any violation of these Terms.</li>
        <li>Our rights under this Agreement will survive any termination of this Agreement.</li>
      </ul>

      <h2>M. Modifications to the Terms</h2>
      <p>We reserve the right, at our sole discretion, to amend these Terms at any time and will update these Terms in the event of any such amendments. We will notify you of material changes to this Agreement, at least 14 days prior to the change taking effect by posting a notice on our Website. For non-material modifications, your continued use of the Website constitutes agreement to our revisions of these Terms.</p>

      <h2>N. Contact Us</h2>
      <p>If you have any questions or concerns about these Terms, please <NavLink to='/contact'>contact us.</NavLink></p>

    </div>
  );
}

export default Terms;